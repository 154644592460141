@tailwind base;
@tailwind components;
@tailwind utilities;


html{
  scrollbar-width: thin !important;
  scrollbar-color: #6c6c6c transparent;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.chat-history-wrapper {
  margin-top: 35px;
}

.fui-ChatMessage__body,
.fui-ChatMyMessage__body {
  line-height: 14px !important;
  white-space: break-spaces !important;
  @apply !leading-normal;
}

.text-area > textarea{
  padding-right: 45px !important;
  scrollbar-width: thin !important;
  scrollbar-color: #6c6c6c transparent;
}